var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as SdkErrors from '../common/errors';
function executeRequestWithRetries(input, init, amountOfRetries = 3) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(input, init);
            return response;
        }
        catch (error) {
            if (amountOfRetries === 1) {
                return Promise.reject(new SdkErrors.NetworkError(`Failed to fetch ${input}`));
            }
            return executeRequestWithRetries(input, init, --amountOfRetries);
        }
    });
}
export function makeRequest(input, init) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield executeRequestWithRetries(input, init, 3);
            if (response.status === 401 || response.status === 403) {
                return Promise.reject(new SdkErrors.PermissionDeniedError('You are not authorized to perform this action.'));
            }
            else if (response.status === 500) {
                return Promise.reject(new SdkErrors.InternalServerError());
            }
            else if (response.status < 200 || response.status > 299) {
                return Promise.reject(new SdkErrors.InvalidResponseError(`Invalid status code received. Expected in range 200~299, but received ${response.status}`));
            }
            return response;
        }
        catch (error) {
            if (error instanceof SdkErrors.SdkError) {
                return Promise.reject(error);
            }
            return Promise.reject(new SdkErrors.UnknownError('Failed to make request', error));
        }
    });
}
