var ErrorType;
(function (ErrorType) {
    ErrorType["Runtime"] = "runtime";
    ErrorType["Configuration"] = "configuration";
})(ErrorType || (ErrorType = {}));
export function isSdkError(error) {
    return (error &&
        typeof error.id === 'string' &&
        typeof error.type === 'string' &&
        (error.type === ErrorType.Runtime || error.type === ErrorType.Configuration));
}
export class SdkError extends Error {
    constructor(type, id, message) {
        super(message);
        this.id = id;
        this.type = type;
    }
    toString() {
        return `[${this.type}] ${this.id} (${this.name}): ${this.message}`;
    }
}
;
export class PermissionDeniedError extends SdkError {
    constructor(message) {
        super(ErrorType.Configuration, 'PERMISSION_DENIED', message);
    }
}
export class NetworkError extends SdkError {
    constructor(message) {
        super(ErrorType.Runtime, 'NETWORK_ERROR', message);
    }
}
export class InvalidResponseError extends SdkError {
    constructor(message) {
        super(ErrorType.Runtime, 'INVALID_RESPONSE', message);
    }
}
export class InternalServerError extends SdkError {
    constructor() {
        super(ErrorType.Runtime, 'INTERNAL_SERVER_ERROR', 'Internal server error');
    }
}
export class UnknownError extends SdkError {
    constructor(message, innerError) {
        super(ErrorType.Runtime, 'UNKNOWN', message);
        this.innerError = innerError;
    }
    toString() {
        return this.innerError ?
            `${super.toString()}\n\t${this.innerError.toString()}` :
            super.toString();
    }
}
