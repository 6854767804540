var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnalyticsServer } from "./server/server";
import * as fingerprint_1 from "./utils/fingerprint";
export { fingerprint_1 as fingerprint };
import uuid from "./utils/uuid";
import * as SdkErrors from "./common/errors";
const ANALYTICS_LOCAL_STORAGE_KEY = "@caf/analytics";
const TRACKING_ID_LOCAL_STORAGE_KEY = "@caf/trackingId"; // to keep compatibility with sdk
export class Sdk {
    constructor(options) {
        this.isInitialized = false;
        this.options = options;
        this.server = new AnalyticsServer(options.stage !== "prod");
        this.isDisabled = false;
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isInitialized === true) {
                return Promise.resolve(false);
            }
            yield this.initializeAnalyticsInfo();
            console.log("[ANALYTICS] Initialized SDK with options:", this.options);
            this.isInitialized = true;
            return Promise.resolve(true);
        });
    }
    disableAnalytics() {
        this.isDisabled = true;
    }
    enableAnalytics() {
        this.isDisabled = false;
    }
    initializeAnalyticsInfo() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log("[ANALYTICS] Initializing analytics info");
            if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.sourceProject) {
                yield this.createAndSaveTrackingIdFromServer();
            }
            this.createAndSaveTrackingId();
            yield this.createAndSaveDeviceInfo();
            return Promise.resolve(true);
        });
    }
    log(type, version, log) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isDisabled) {
                console.log("[ANALYTICS] SDK is disabled");
                return Promise.resolve(null);
            }
            if (!this.isInitialized) {
                return Promise.reject(new SdkErrors.UnknownError(`SDK is not initialized, call Sdk.initialize(); first`));
            }
            try {
                const data = {
                    source: this.options.source,
                    type: type,
                    version: version,
                    data: log,
                };
                console.log(`[ANALYTICS] Sending log`);
                yield this.server.sendLog(data);
                return Promise.resolve(null);
            }
            catch (error) {
                return Promise.resolve(this.isSdkError(error)
                    ? error
                    : new SdkErrors.UnknownError(`Unknown SDK error`, error));
            }
        });
    }
    indicator(type, version, indicator) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isDisabled) {
                console.log("[ANALYTICS] SDK is disabled");
                return Promise.resolve(null);
            }
            if (!this.isInitialized) {
                return Promise.reject(new SdkErrors.UnknownError(`SDK is not initialized, call Sdk.initialize(); first`));
            }
            try {
                const data = {
                    source: this.options.source,
                    type: type,
                    version: version,
                    data: indicator,
                };
                console.log(`[ANALYTICS] Sending indicator`);
                yield this.server.sendIndicator(data);
                return Promise.resolve(null);
            }
            catch (error) {
                return Promise.resolve(this.isSdkError(error)
                    ? error
                    : new SdkErrors.UnknownError(`Unknown SDK error`, error));
            }
        });
    }
    logCheckpoint(checkpoint) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isDisabled) {
                console.log("[ANALYTICS] SDK is disabled");
                return Promise.resolve(null);
            }
            if (!this.isInitialized) {
                return Promise.reject(new SdkErrors.UnknownError(`SDK is not initialized, call Sdk.initialize(); first`));
            }
            try {
                const trackingId = this.getAnalyticsInfo().trackingIdFromServer;
                console.log(`[ANALYTICS] Sending checkpoint`);
                yield this.server.sendCheckpoint(trackingId, checkpoint);
                return Promise.resolve(null);
            }
            catch (error) {
                return Promise.resolve(this.isSdkError(error)
                    ? error
                    : new SdkErrors.UnknownError(`Unknown SDK error`, error));
            }
        });
    }
    createAndSaveTrackingIdFromServer() {
        return __awaiter(this, void 0, void 0, function* () {
            const analyticsInfo = this.getAnalyticsInfo();
            // If tracking ID already set, return to avoid override
            if (analyticsInfo === null || analyticsInfo === void 0 ? void 0 : analyticsInfo.trackingIdFromServer) {
                console.log("[ANALYTICS] tracking ID from server already exists");
                return;
            }
            console.log("[ANALYTICS] Create tracking ID from server");
            const currentTrackingId = localStorage.getItem(TRACKING_ID_LOCAL_STORAGE_KEY);
            let newTrackingId = null;
            if (currentTrackingId) {
                newTrackingId = currentTrackingId;
            }
            else {
                const deviceInfo = yield this.options.getDeviceInfoFunctionOld();
                const { trackingId } = yield this.server.createTrackingId(this.options.sourceProject, deviceInfo);
                newTrackingId = trackingId;
            }
            localStorage.setItem(TRACKING_ID_LOCAL_STORAGE_KEY, newTrackingId);
            if (!analyticsInfo) {
                this.setAnalyticsInfo({ trackingIdFromServer: newTrackingId });
            }
            else {
                analyticsInfo.trackingIdFromServer = newTrackingId;
                this.setAnalyticsInfo(analyticsInfo);
            }
            return Promise.resolve(newTrackingId);
        });
    }
    createAndSaveTrackingId() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const analyticsInfo = this.getAnalyticsInfo();
            // If tracking ID already set, return to avoid override
            // Exception if has tracking ID from options, then we can override
            if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.trackingId) && (analyticsInfo === null || analyticsInfo === void 0 ? void 0 : analyticsInfo.trackingId)) {
                console.log("[ANALYTICS] tracking ID already exists");
                return;
            }
            let newTrackingId = null;
            if ((_b = this.options) === null || _b === void 0 ? void 0 : _b.trackingId) {
                console.log("[ANALYTICS] Override tracking ID from options");
                newTrackingId = this.options.trackingId;
            }
            else {
                console.log("[ANALYTICS] Creating new tracking ID");
                newTrackingId = uuid();
            }
            if (!analyticsInfo) {
                this.setAnalyticsInfo({ trackingId: newTrackingId });
            }
            else {
                analyticsInfo.trackingId = newTrackingId;
                this.setAnalyticsInfo(analyticsInfo);
            }
        });
    }
    createAndSaveDeviceInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("[ANALYTICS] Create device info");
            const analyticsInfo = this.getAnalyticsInfo();
            const device = yield this.options.getDeviceInfoFunction();
            if (!analyticsInfo) {
                this.setAnalyticsInfo({ device });
                return;
            }
            analyticsInfo.device = device;
            this.setAnalyticsInfo(analyticsInfo);
        });
    }
    setAnalyticsInfo(analyticsInfo) {
        try {
            localStorage.setItem(ANALYTICS_LOCAL_STORAGE_KEY, JSON.stringify(analyticsInfo));
        }
        catch (error) { }
    }
    getAnalyticsInfo() {
        try {
            return (JSON.parse(localStorage.getItem(ANALYTICS_LOCAL_STORAGE_KEY)) ||
                null);
        }
        catch (error) {
            return null;
        }
    }
    isSdkError(error) {
        return SdkErrors.isSdkError(error);
    }
}
export default Sdk;
