var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeRequest } from "../utils/request";
export class AnalyticsServer {
    constructor(pointToDev) {
        this.baseDevUrl = "https://api.dev.analytics.combateafraude.com";
        this.baseProdUrl = "https://api.analytics.combateafraude.com";
        this.baseUrl = pointToDev === true ? this.baseDevUrl : this.baseProdUrl;
    }
    createTrackingId(sourceProject, deviceInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.makeRequestAndGetBody(`${this.baseProdUrl}/tracking/`, {
                method: "POST",
                body: JSON.stringify({
                    sourceProject,
                    otherInfo: deviceInfo,
                }),
            });
            return response;
        });
    }
    sendCheckpoint(trackingId, checkpoint) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield makeRequest(`${this.baseProdUrl}/tracking/${trackingId}/checkpoint`, {
                method: "POST",
                credentials: "omit",
                body: JSON.stringify({
                    time: new Date().getTime(),
                    checkpoint,
                }),
            });
        });
    }
    sendLog(data) {
        return __awaiter(this, void 0, void 0, function* () {
            yield makeRequest(`${this.baseUrl}/logs`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Object.assign({ date: new Date().toISOString() }, data)),
            });
        });
    }
    sendIndicator(data) {
        return __awaiter(this, void 0, void 0, function* () {
            yield makeRequest(`${this.baseUrl}/indicators`, {
                method: "POST",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(Object.assign({ date: new Date().toISOString() }, data)),
            });
        });
    }
    makeRequestAndGetBody(input, init) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!init)
                init = {};
            return makeRequest(input, init)
                .then((x) => x.json())
                .then((x) => x);
        });
    }
}
