var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import fingerprint from '@fingerprintjs/fingerprintjs';
import { UAParser } from 'ua-parser-js';
// function normalizeComponents(components: any) {
//     for (const prop of Object.keys(components)) {
//         delete components[prop].duration;
//     }
// }
export function getBrowserFingerprint() {
    return __awaiter(this, void 0, void 0, function* () {
        const parser = new UAParser();
        const deviceInfo = parser.getResult();
        const fingerprintClient = yield fingerprint.load();
        const { visitorId } = yield fingerprintClient.get();
        // normalizeComponents(components);
        delete deviceInfo.ua;
        return Object.assign({ fingerprint: visitorId }, deviceInfo);
    });
}
